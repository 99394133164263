import { useEffect, useState } from "react"
import { useTheme } from "next-themes"
import { useRouter } from "next/router"
import cn from "classnames"
import Image from "next/image"

import LocationForm from "../forms/LocationForm/LocationForm"
import HourseStep from "../forms/HoursStep/HoursStep"
import MainSectionSlider, {
  MainSectionSliderProps,
} from "../MainSectionSlider/MainSectionSlider"
import Toggle from "../forms/Toggle/Toggle"

import styles from "./styles.module.scss"
import SectionTitle from "../SectionTitle/SectionTitle"

export type MainSectionProps = {} & Pick<MainSectionSliderProps, "images">

const MainSection = ({ images }: MainSectionProps) => {
  const [active, setActive] = useState(true)
  const { resolvedTheme } = useTheme()
  const router = useRouter()

  useEffect(() => {
    if (router.query?.hours) {
      setActive(false)
    }
  }, [router.query?.hours])

  return (
    <div className={styles.section}>
      <div className={styles.section__wrapper}>
        <div
          className={cn(
            styles.section__form,
            resolvedTheme === "dark" && styles.section__form_dark
          )}
        >
          <Toggle
            isFirstActive={active}
            onToggle={setActive}
            className={styles.section__toggle}
            resolvedTheme={resolvedTheme}
          />
          {active && (
            <LocationForm
              resolvedTheme={resolvedTheme}
              nextStepUrl={
                router.pathname === "/cars/[slug]" ? "/order/details" : "/cars"
              }
            />
          )}
          {!active && (
            <HourseStep
              resolvedTheme={resolvedTheme}
              nextStepUrl={
                router.pathname === "/cars/[slug]" ? "/order/details" : "/cars"
              }
            />
          )}
        </div>

        {images.length > 1 && (
          <MainSectionSlider
            images={images}
            className={styles.section__slider}
          />
        )}
        {images.length === 1 && (
          <div className={styles.section__image_wrapper}>
            <div className={styles.section__image_container}>
              <Image
                src={images[0].fields.image.fields.file.url}
                alt={images[0].fields.alt}
                className={styles.section__image}
                width={1202}
                height={970}
                priority
              />
            </div>
            {images[0].fields.slideTitle && (
              <SectionTitle
                variant="h1"
                className={cn(
                  styles.section__title,
                  resolvedTheme === "dark" && styles.section__title_dark
                )}
              >
                {images[0].fields.slideTitle}
              </SectionTitle>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default MainSection
